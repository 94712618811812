import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DbschemaService {

  private API_URL : string = `${environment.authUrl + '/admin/data/database-schemas'}`;
  private API_SAVE : string = '/save';
  private API_PREDATA : string = '/pre-data'

  constructor(private http: HttpClient) { }
  
  getDBSData(): Observable<{data:any, status_code:number}>{
    return this.http.get<{data:any, status_code:number}>(`${this.API_URL}`);
  }

  getDBSPreData(): Observable<{data:any, status_code:number}>{
    return this.http.get<{data:any, status_code:number}>(`${this.API_URL}${this.API_PREDATA}`);
  }

  postDBSchema(body : {name:string, sistema: string, file: string}):Observable<{data:any, status_code:number}>{
    return this.http.post<{data:any, status_code:number}>(`${this.API_URL}${this.API_SAVE}`, body);
  }

  getDBS(id : string): Observable<{data:any, status_code:number}>{
    return this.http.get<{data:any, status_code:number}>(`${this.API_URL}/${id}`)
  }

  deleteDBS(id : string): Observable<{data:any, status_code:number}>{
    return this.http.delete<{data:any, status_code:number}>(`${this.API_URL}/${id}`)
  }
  
}
